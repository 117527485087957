* {
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
}

.leaflet-container {
  width: 100vw;
  height: 100vh;
}
