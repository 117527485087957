/* Mobile View */
@media (max-width: 430px) {
  .desktop-wrapper {
    display: none;
  }

  .mobile-wrapper {
    display: block;
    /* Add mobile-specific inline styles here */
  }
}

/* Desktop View */
@media (min-width: 700px) {
  .mobile-wrapper {
    display: none;
  }

  .desktop-wrapper {
    display: block;
    /* Add desktop-specific inline styles here */
  }
}
.text {
  color: black;
  font-family: Helvetica;
}
.text:hover {
  color: blue;
  text-decoration: underline;
}

.black-text {
  color: black;
}
.black-text:hover {
  color: blue;
}

.mobile-select {
  flex: 1;
  justify-content: center;
  font-size: 20px;
  margin-top: 10px;
  padding-left: 8px;
}

.mobile-input {
  height: 30px;
  width: 380px;
}

.input-and-icon {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  padding-left: 8px;
}

.image-container {
  padding-top: 20px;
  padding-bottom: 0px;
}

.image-and-text {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.artwork-text {
  padding-left: 30px;
}

.unordered li {
  font-family: "Times New Roman", Times, serif;
  font-size: 20px !important;
}

.image {
  max-width: 90%;
  padding-left: 6px;
  height: auto;
}

.unordered li {
  font-family: "Times New Roman", Times, serif;
  font-size: 20px !important;
}

/* .museum-link {
  color: "#0000FF";
  text-decoration: "none";
} */

.PK-copyright {
  padding-top: 20px;
}
